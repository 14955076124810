import { CameraAlt, FiberManualRecord } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, IconButton, Link, MenuItem, Popover, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signout, toggleAuthModal } from '../../actions';
import Help from '../Help';
import './usermenuoptions.scss';

function UserMenuOptions({ name }) {
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [currentUser, setCurrentUser] = useState(null);
	const isVerified = JSON.parse(localStorage.getItem('details'))?.is_email_verified;

	useEffect(() => {
		const fetchAgent = async () => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}register/agents/${auth.typeId}/`);
				//console.log(res.data)
				setCurrentUser(res.data);
			} catch (error) {
				//console.log(error.response)
			}
		};

		if (auth?.role === 'agent') {
			fetchAgent();
		}
	}, [auth]);

	const signOut = () => {
		dispatch(signout());
		navigate('/');
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const selectedListing = useSelector((state) => state.auth.selectedListing);

	const editProfilePage = auth.role !== 'ind_prof' ? '/profile/edit' : '/ind_prof';

	return (
		<>
			{auth.role === 'agent' || auth.role === 'realty_agent' || auth.role === 'ind_prof' ? (
				<Box display={'flex'} gap='.4rem' ml={'auto'} mr={'3rem'} alignItems='center'>
					<Help />
					<Avatar
						onClick={(e) => handleClick(e)}
						sx={{ ml: 1, height: '2.8rem', width: '2.8rem', bgcolor: 'primary.main', cursor: 'pointer' }}
						src={auth.role === 'ind_prof' ? selectedListing?.icon_file : null}
					>
						{auth.first_name?.charAt(0).toUpperCase()}
					</Avatar>
					<Popover
						sx={{ mt: 1 }}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<Box width='19rem'>
							<Box padding={'1.5rem'} textAlign='center'>
								<Box position={'relative'} width='fit-content' m={'0 auto'}>
									<Avatar
										src={
											currentUser?.[auth.role !== 'ind_prof' ? 'profile_image' : 'icon_file']
												? currentUser?.[auth.role !== 'ind_prof' ? 'profile_image' : 'icon_file']
												: null
										}
										sx={{ ml: 1, height: '5rem', width: '5rem', m: '0 auto' }}
									>
										{[auth.role === 'ind_prof' ? selectedListing : currentUser]?.[auth.role !== 'ind_prof' ? 'profile_image' : 'icon_file'] ? null : (
											<Typography variant='h3'>{auth.first_name?.charAt(0).toUpperCase()}</Typography>
										)}
									</Avatar>
									<IconButton
										onClick={() => {
											isVerified ? navigate(editProfilePage) : dispatch(toggleAuthModal());
											handleClose();
										}}
										sx={{
											position: 'absolute',
											bottom: '-5px',
											right: '-8px',
											background: '#fff',
											boxShadow: '1px 0 5px rgba(0,0,0, 20%)',
											'&:hover': {
												background: '#fff',
											},
										}}
									>
										<CameraAlt sx={{ fontSize: '1.2rem' }} />
									</IconButton>
								</Box>
								<Typography variant='h6' mt={2}>
									{auth.first_name + ' ' + auth.last_name}
								</Typography>
								<Typography variant='body2' color='textSecondary' mb={2}>
									{auth.email}
								</Typography>
								<Button
									sx={{
										border: `1px solid ${grey[300]}`,
										borderRadius: '5px',
										textTransform: 'none',
										width: '75%',
									}}
									color='secondary'
									onClick={() => {
										isVerified ? navigate(editProfilePage) : dispatch(toggleAuthModal());
										handleClose();
									}}
								>
									Manage your account
								</Button>
							</Box>
							<Divider sx={{ bgcolor: grey[100] }} />
							{auth.role !== 'ind_prof' ? (
								<Box padding={'1rem'}>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/agent-dashboard') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Dashboard
									</MenuItem>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/profile') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										My Profile
									</MenuItem>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/leads-notification') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Leads Notification
									</MenuItem>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/my-subscriptions') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Settings
									</MenuItem>
								</Box>
							) : (
								<Box padding={'1rem'}>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/industry-dashboard') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Dashboard
									</MenuItem>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/my-listing/all') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Business Listing
									</MenuItem>
									<MenuItem
										component='a'
										sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
										onClick={() => {
											isVerified ? navigate('/my-subscriptions') : dispatch(toggleAuthModal());
											handleClose();
										}}
									>
										Settings
									</MenuItem>
								</Box>
							)}
							<Divider sx={{ bgcolor: grey[100] }} />
							<Box padding={'1.5rem 2rem'} textAlign={'center'}>
								<Button
									variant='contained'
									disableElevation
									sx={{
										textTransform: 'none',
										background: 'transparent',
										color: (theme) => theme.secondaryColor,
										'&:hover': {
											color: '#fff',
											background: (theme) => theme.secondaryColor,
										},
									}}
									onClick={signOut}
								>
									Sign Out
								</Button>
								<span style={{ display: 'flex', marginTop: '1rem', justifyContent: 'space-evenly', alignItems: 'center' }}>
									<Link
										sx={{
											fontSize: '.9rem',
											color: grey[500],
											cursor: 'pointer',
											'&:hover': {
												color: (theme) => theme.primaryColor,
											},
										}}
										underline='none'
										href='terms-of-service'
									>
										Terms of Services
									</Link>
									<FiberManualRecord sx={{ fontSize: '.4rem' }} />
									<Link
										sx={{
											fontSize: '.9rem',
											color: grey[500],
											cursor: 'pointer',
											'&:hover': {
												color: (theme) => theme.primaryColor,
											},
										}}
										underline='none'
										href='privacy-policy'
									>
										Privacy Policy
									</Link>
								</span>
							</Box>
						</Box>
					</Popover>
				</Box>
			) : null}

			{auth.role === 'client' && (
				<Box display={'flex'} gap='.4rem' ml={'auto'} mr={'3rem'} alignItems='center'>
					<Help />
					<Avatar onClick={(e) => handleClick(e)} sx={{ ml: 1, height: '2.8rem', width: '2.8rem', bgcolor: 'primary.main', cursor: 'pointer' }}>
						{auth.first_name?.charAt(0).toUpperCase()}
					</Avatar>

					<Popover
						sx={{ mt: 1 }}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						transformOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Box width='19rem'>
							<Box padding={'1.5rem'} textAlign='center'>
								<Box position={'relative'} width='fit-content' m={'0 auto'}>
									<Avatar
										src={currentUser?.profile_image ? currentUser.profile_image : null}
										sx={{ ml: 1, height: '5rem', width: '5rem', m: '0 auto' }}
									>
										{currentUser?.profile_image ? null : <Typography variant='h3'>{auth.first_name?.charAt(0).toUpperCase()}</Typography>}
									</Avatar>
									{auth.role !== 'client' && (
										<IconButton
											href='/profile/edit'
											sx={{
												position: 'absolute',
												bottom: '-5px',
												right: '-8px',
												background: '#fff',
												boxShadow: '1px 0 5px rgba(0,0,0, 20%)',
												'&:hover': {
													background: '#fff',
												},
											}}
										>
											<CameraAlt sx={{ fontSize: '1.2rem' }} />
										</IconButton>
									)}
								</Box>
								<Typography variant='h6' mt={2}>
									{auth.first_name + ' ' + auth.last_name}
								</Typography>
								<Typography variant='body2' color='textSecondary' mb={2}>
									{auth.email}
								</Typography>
								{/* <Button
									sx={{
										border: `1px solid ${grey[300]}`,
										borderRadius: '5px',
										textTransform: 'none',
										width: '75%',
									}}
									color='secondary'
									href='/profile/edit'
								>
									Manage your account
								</Button> */}
							</Box>
							<Divider sx={{ bgcolor: grey[100] }} />
							<Box padding={'1rem'}>
								<MenuItem
									component='a'
									sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
									onClick={() => {
										isVerified ? navigate('/client-dashboard') : dispatch(toggleAuthModal());
										handleClose();
									}}
								>
									Dashboard
								</MenuItem>
								<MenuItem
									component='a'
									sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
									onClick={() => {
										isVerified ? navigate('/profile') : dispatch(toggleAuthModal());
										handleClose();
									}}
								>
									My Profile
								</MenuItem>
								{/* <MenuItem sx={{ borderRadius: '8px', fontSize: '1.1rem' }}>Saved Homes</MenuItem> */}
								<MenuItem
									component='a'
									sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
									onClick={() => {
										isVerified ? navigate('/saved-homes') : dispatch(toggleAuthModal());
										handleClose();
									}}
								>
									My Saved Homes
								</MenuItem>
								<MenuItem
									component='a'
									sx={{ borderRadius: '8px', fontSize: '1.1rem' }}
									onClick={() => {
										isVerified ? navigate('/my-subscriptions') : dispatch(toggleAuthModal());
										handleClose();
									}}
								>
									Settings
								</MenuItem>
							</Box>
							<Divider sx={{ bgcolor: grey[100] }} />
							<Box padding={'1.5rem 2rem'} textAlign={'center'}>
								<Button
									variant='contained'
									disableElevation
									sx={{
										textTransform: 'none',
										background: 'transparent',
										color: (theme) => theme.secondaryColor,
										'&:hover': {
											color: '#fff',
											background: (theme) => theme.secondaryColor,
										},
									}}
									onClick={signOut}
								>
									Sign Out
								</Button>
								<span
									style={{
										display: 'flex',
										marginTop: '1rem',
										justifyContent: 'space-evenly',
										alignItems: 'center',
									}}
								>
									<Link
										sx={{
											fontSize: '.9rem',
											color: grey[500],
											cursor: 'pointer',
											'&:hover': {
												color: (theme) => theme.primaryColor,
											},
										}}
										underline='none'
										href='/terms-of-service'
									>
										Terms of Services
									</Link>
									<FiberManualRecord sx={{ fontSize: '.4rem' }} />
									<Link
										sx={{
											fontSize: '.9rem',
											color: grey[500],
											cursor: 'pointer',
											'&:hover': {
												color: (theme) => theme.primaryColor,
											},
										}}
										underline='none'
										href='/privacy-policy'
									>
										Privacy Policy
									</Link>
								</span>
							</Box>
						</Box>
					</Popover>
				</Box>
			)}
		</>
	);
}

export default UserMenuOptions;
